import React from 'react'

const RubleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      color="#B6BEC7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.3334 30.6068H24.2162V13.3334H34.7396C37.8334 13.3334 40.2943 14.1224 42.1224 15.7006C43.9506 17.2631 44.8646 19.5912 44.8646 22.6849V24.1381C44.8646 27.3724 43.9427 29.8256 42.099 31.4974C40.2709 33.1537 37.8177 33.9818 34.7396 33.9818H29.9818V39.4427H39.3334V42.7943H29.9818V49.3334H24.2162V42.7943H21.3334V39.4427H24.2162V33.9818H21.3334V30.6068ZM34.2474 18.6068H29.9818V28.6849H34.2474C35.8412 28.6849 37.0443 28.3099 37.8568 27.5599C38.6849 26.8099 39.099 25.5052 39.099 23.6459V23.1771C39.099 21.4584 38.6927 20.2709 37.8802 19.6146C37.0677 18.9427 35.8568 18.6068 34.2474 18.6068Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default RubleIcon

import React from 'react'

const PersonDoubleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      color="#B6BEC7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2311 27.1291C27.3043 27.1291 29.7956 24.6378 29.7956 21.5646C29.7956 18.4914 27.3043 16.0001 24.2311 16.0001C21.1579 16.0001 18.6666 18.4914 18.6666 21.5646C18.6666 24.6378 21.1579 27.1291 24.2311 27.1291ZM24.2311 32.4624C30.2498 32.4624 35.1289 27.5833 35.1289 21.5646C35.1289 15.5459 30.2498 10.6667 24.2311 10.6667C18.2124 10.6667 13.3333 15.5459 13.3333 21.5646C13.3333 27.5833 18.2124 32.4624 24.2311 32.4624Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3333 40.0001C15.4422 40.0001 10.6666 44.7757 10.6666 50.6667V53.0527H5.33325V50.6667C5.33325 41.8302 12.4967 34.6667 21.3333 34.6667H28.3508C37.1874 34.6667 44.3508 41.8302 44.3508 50.6667V53.0527H39.0175V50.6667C39.0175 44.7757 34.2419 40.0001 28.3508 40.0001H21.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1289 21.5646C43.1289 24.6378 40.6376 27.1291 37.5644 27.1291C37.487 27.1291 37.4099 27.1275 37.3333 27.1244V32.46C37.4101 32.4616 37.4872 32.4624 37.5644 32.4624C43.5831 32.4624 48.4623 27.5833 48.4623 21.5646C48.4623 15.5459 43.5831 10.6667 37.5644 10.6667C37.4872 10.6667 37.4101 10.6676 37.3333 10.6692V16.0048C37.4099 16.0017 37.487 16.0001 37.5644 16.0001C40.6376 16.0001 43.1289 18.4914 43.1289 21.5646ZM45.3333 35.0847V40.6406C49.4272 42.131 52.3508 46.0574 52.3508 50.6667V53.0527H57.6842V50.6667C57.6842 43.0856 52.4115 36.7359 45.3333 35.0847Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PersonDoubleIcon

import React from 'react'

const FrameCopyCodeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      color="#B6BEC7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5523 44.5523C19.5109 45.5937 17.8225 45.5937 16.7811 44.5523L6.11442 33.8857C5.07302 32.8443 5.07302 31.1558 6.11442 30.1144L16.7811 19.4478C17.8225 18.4064 19.5109 18.4064 20.5523 19.4478C21.5937 20.4891 21.5937 22.1776 20.5523 23.219L11.7713 32L20.5523 40.7811C21.5937 41.8225 21.5937 43.5109 20.5523 44.5523Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.4478 19.4478C44.4892 18.4064 46.1776 18.4064 47.219 19.4478L57.8857 30.1144C58.9271 31.1558 58.927 32.8443 57.8857 33.8857L47.219 44.5523C46.1776 45.5937 44.4892 45.5937 43.4478 44.5523C42.4064 43.5109 42.4064 41.8225 43.4478 40.7811L52.2288 32L43.4478 23.219C42.4064 22.1776 42.4064 20.4891 43.4478 19.4478Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.066 10.7693C39.4821 11.1739 40.302 12.6499 39.8974 14.066L29.2308 51.3993C28.8262 52.8154 27.3502 53.6354 25.9341 53.2308C24.518 52.8262 23.698 51.3502 24.1026 49.9341L34.7693 12.6008C35.1739 11.1847 36.6499 10.3647 38.066 10.7693Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FrameCopyCodeIcon

import React from 'react'

const MobileModuleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      color="#B6BEC7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_23392_87164)">
        <path
          d="M20.0556 13.0375C20.0556 12.0227 20.8839 11.2 21.9058 11.2H28.0734C29.0952 11.2 29.9236 12.0227 29.9236 13.0375V19.1625C29.9236 20.1773 29.0952 21 28.0734 21H21.9058C20.8839 21 20.0556 20.1773 20.0556 19.1625V13.0375Z"
          fill="currentColor"
        />
        <path
          d="M32.7431 16.1C32.7431 13.3938 34.9521 11.2 37.6771 11.2C40.4021 11.2 42.6111 13.3938 42.6111 16.1C42.6111 18.8062 40.4021 21 37.6771 21C34.9521 21 32.7431 18.8062 32.7431 16.1Z"
          fill="currentColor"
        />
        <path
          d="M32.7431 25.6375C32.7431 24.6227 33.5714 23.8 34.5933 23.8H40.7609C41.7827 23.8 42.6111 24.6227 42.6111 25.6375V31.7625C42.6111 32.7773 41.7827 33.6 40.7609 33.6H34.5933C33.5714 33.6 32.7431 32.7773 32.7431 31.7625V25.6375Z"
          fill="currentColor"
        />
        <path
          d="M20.0556 25.6375C20.0556 24.6227 20.8839 23.8 21.9058 23.8H28.0734C29.0952 23.8 29.9236 24.6227 29.9236 25.6375V31.7625C29.9236 32.7773 29.0952 33.6 28.0734 33.6H21.9058C20.8839 33.6 20.0556 32.7773 20.0556 31.7625V25.6375Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4444 0C14.8853 0 12 2.86538 12 6.4V57.6C12 61.1346 14.8853 64 18.4444 64H44.2222C47.7814 64 50.6667 61.1346 50.6667 57.6V6.4C50.6667 2.86538 47.7814 0 44.2222 0H18.4444ZM18.4444 3.2C16.6649 3.2 15.2222 4.63269 15.2222 6.4V57.6C15.2222 59.3673 16.6649 60.8 18.4444 60.8H44.2222C46.0018 60.8 47.4444 59.3673 47.4444 57.6V6.4C47.4444 4.63269 46.0018 3.2 44.2222 3.2H18.4444Z"
          fill="currentColor"
        />
        <path
          d="M23.2778 52.8C23.2778 51.9163 23.9991 51.2 24.8889 51.2H37.7778C38.6676 51.2 39.3889 51.9163 39.3889 52.8C39.3889 53.6837 38.6676 54.4 37.7778 54.4H24.8889C23.9991 54.4 23.2778 53.6837 23.2778 52.8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_23392_87164">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MobileModuleIcon

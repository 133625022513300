// extracted by mini-css-extract-plugin
export var content = "ModuleSectionItem2-module--content--cYf8U";
export var column = "ModuleSectionItem2-module--column--Qlr5t";
export var column_left = "ModuleSectionItem2-module--column_left--0OW0X";
export var quote = "ModuleSectionItem2-module--quote--rD06l";
export var quote_info = "ModuleSectionItem2-module--quote_info--sGXaJ";
export var quote_text = "ModuleSectionItem2-module--quote_text--m1LN8";
export var title = "ModuleSectionItem2-module--title--Oc706";
export var title_hover = "ModuleSectionItem2-module--title_hover--EyQyG";
export var title_center = "ModuleSectionItem2-module--title_center--Z4aKr";
export var title_right = "ModuleSectionItem2-module--title_right--NHQ-6";
export var position = "ModuleSectionItem2-module--position--s1WKT";
export var description = "ModuleSectionItem2-module--description--bSkzI";
export var avatar = "ModuleSectionItem2-module--avatar--2Wtx7";
export var reverse = "ModuleSectionItem2-module--reverse--CVPbC";
export var btn = "ModuleSectionItem2-module--btn--dpRnA";
export var is_mobile = "ModuleSectionItem2-module--is_mobile--ioYF1";
export var is_desktop = "ModuleSectionItem2-module--is_desktop--cZiCH";
export var arrow = "ModuleSectionItem2-module--arrow--3VprZ";
// extracted by mini-css-extract-plugin
export var section = "Bim360Migrate-module--section--vRKUr";
export var anchor_link = "Bim360Migrate-module--anchor_link--GsfJG";
export var lists = "Bim360Migrate-module--lists--05kxA";
export var icon = "Bim360Migrate-module--icon--ziox5";
export var review_cards = "Bim360Migrate-module--review_cards--j778j";
export var review_cards_block = "Bim360Migrate-module--review_cards_block--NO9tB";
export var review_cards_image = "Bim360Migrate-module--review_cards_image--1AqZ8";
export var review_cards_info = "Bim360Migrate-module--review_cards_info--Zes+L";
export var imageBg = "Bim360Migrate-module--imageBg--SIKfb";
export var overlay = "Bim360Migrate-module--overlay--0BnHC";
export var form_block = "Bim360Migrate-module--form_block---BP51";
export var form_block_quote = "Bim360Migrate-module--form_block_quote--9KXr5";
export var hiddenMobile = "Bim360Migrate-module--hiddenMobile--sx0Cj";
export var hiddenDesktop = "Bim360Migrate-module--hiddenDesktop--VoQ8q";
export var subTitle = "Bim360Migrate-module--subTitle--6nDMb";
import React from 'react'

const EyeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      color="#B6BEC7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.7976 40.8301C51.6398 38.1406 53.3333 34.9367 53.3333 32C53.3333 29.0634 51.6398 25.8594 47.7976 23.17C43.9723 20.4923 38.4036 18.6667 31.9999 18.6667C25.5962 18.6667 20.0275 20.4923 16.2022 23.17C12.3601 25.8594 10.6666 29.0634 10.6666 32C10.6666 34.9367 12.3601 38.1406 16.2022 40.8301C20.0275 43.5078 25.5962 45.3334 31.9999 45.3334C38.4036 45.3334 43.9723 43.5078 47.7976 40.8301ZM31.9999 50.6667C46.7275 50.6667 58.6666 42.3094 58.6666 32C58.6666 21.6907 46.7275 13.3334 31.9999 13.3334C17.2723 13.3334 5.33325 21.6907 5.33325 32C5.33325 42.3094 17.2723 50.6667 31.9999 50.6667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9999 34.6667C33.4727 34.6667 34.6666 33.4728 34.6666 32C34.6666 30.5273 33.4727 29.3334 31.9999 29.3334C30.5272 29.3334 29.3333 30.5273 29.3333 32C29.3333 33.4728 30.5272 34.6667 31.9999 34.6667ZM31.9999 40C36.4182 40 39.9999 36.4183 39.9999 32C39.9999 27.5818 36.4182 24 31.9999 24C27.5816 24 23.9999 27.5818 23.9999 32C23.9999 36.4183 27.5816 40 31.9999 40Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default EyeIcon

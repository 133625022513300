import React, { useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import cn from 'classnames'

import FormDone from './FormDone'
import FormError from './FormError'
import Button from '../button/button'
import Input from '../Common/Input'
import PhoneInput from '../Common/PhoneInput'
import Checkbox from '../Common/Checkbox'
import Typography from '../Common/Typography'

import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { getMailUrl } from '../../utils'
import { AppRegionEnum } from '../../types/app-region.enum'
import {
  emailRegex,
  FormState,
  nameSurnameRegex,
  phoneRegex,
  SIGNAL_GOOGLE_SHEET_URL,
  SIGNAX_GOOGLE_SHEET_URL,
} from './config'
import { IContactFields, RequestFormType } from './types'

interface IProps {
  requestType: RequestFormType
  title?: string
  submitBtnText?: string
  onSuccess?: () => void
  onClose?: () => void
}

export interface IContactModalState {
  title: string
  requestType: RequestFormType
}

const Form: React.FC<IProps> = props => {
  const { location, region } = useContext(GlobalContext)
  const { t } = useTranslations()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IContactFields>({
    defaultValues: {
      name: '',
      company: '',
      email: '',
      phone: '',
    },
    mode: 'all',
  })

  const [state, setState] = useState<FormState>(FormState.Ready)
  const [checkPersonal, setCheckPersonal] = useState(false)

  const {
    requestType,
    title = t('Submit your application'),
    submitBtnText = t('Send'),
    onClose,
    onSuccess,
  } = props
  const hostName = location?.hostname || ''

  const onSubmit: SubmitHandler<IContactFields> = data => {
    setState(FormState.Processing)
    const mailUrl = getMailUrl(hostName, region as AppRegionEnum)
    const formData = new FormData()
    formData.append('title', `${t('message')} ${hostName}: ${title}`)
    formData.append('name', data.name)
    formData.append('company', data.company)
    formData.append('email', data.email)
    formData.append('phone', data.phone)
    formData.append('region', region)

    fetch(mailUrl, {
      body: formData,
      method: 'POST',
    })
      .then((r: Response) => {
        if (r.ok) {
          // Добаляет форму в Google таблицу
          if (process.env.GATSBY_APP_URL === location?.origin) {
            formData.append('requestType', t(requestType))
            fetch(
              region === AppRegionEnum.Ru
                ? SIGNAL_GOOGLE_SHEET_URL
                : SIGNAX_GOOGLE_SHEET_URL,
              {
                body: formData,
                method: 'POST',
              }
            )
          }
          setState(FormState.Done)
          if (onSuccess) {
            onSuccess()
            handleClose()
          }
        } else {
          console.warn(r)
          setState(FormState.Error)
        }
      })
      .catch(e => {
        console.error(e)
        setState(FormState.Error)
      })
      .finally(() => {
        reset()
        setCheckPersonal(false)
      })
  }

  const handleClose = () => {
    if (state === FormState.Processing) {
      return
    }
    setState(FormState.Ready)
    onClose?.()
  }

  if (state === FormState.Error) {
    return <FormError onCLose={handleClose} />
  }

  if (state === FormState.Done) {
    return <FormDone onClose={handleClose} />
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form is-block"
      autoComplete="off"
    >
      <fieldset disabled={state === 'processing'}>
        <Controller
          name="name"
          control={control}
          rules={{ required: true, pattern: nameSurnameRegex }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('Your full name')}
              hasError={!!errors.name}
              className="mb-2"
              type="text"
              fullWidth
            />
          )}
        />
        <Controller
          name="company"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={t('Company/Position')}
              className="mb-2"
              fullWidth
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{ required: true, pattern: emailRegex }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('E-Mail')}
              type="email"
              hasError={!!errors.email}
              className="mb-2"
              fullWidth
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          rules={{ pattern: phoneRegex }}
          render={({ field: { onChange, ...field } }) => (
            <PhoneInput
              {...field}
              onChange={phone => {
                onChange(`+${phone}`)
              }}
              country={region === AppRegionEnum.Ru ? 'ru' : 'ae'}
              hasError={!!errors.phone}
              autoFormat
              inputProps={{
                placeholder: t('Phone number'),
                type: 'tel',
              }}
              countryCodeEditable={true}
            />
          )}
        />
        <div className=" flex flex-col mt-8">
          <div className="flex items-center">
            <Checkbox
              id="personal"
              checked={checkPersonal}
              onChange={checked => setCheckPersonal(checked)}
            />
            <label>
              <Typography variant="body2" style={{ fontSize: 16 }}>
                {`${t('Agreement')} `}
                <a
                  className="has-text-primary"
                  href="/privacy"
                  target="_blank"
                >{`${t('Personal Data')}.`}</a>
              </Typography>
            </label>
          </div>
          <Button
            role="submit"
            style={{ marginTop: 16 }}
            className={cn(state === 'processing' && 'is-loading')}
            disabled={!isValid || !checkPersonal || state !== 'ready'}
            isFullWidth
            isBoxShadow={false}
          >
            {submitBtnText}
          </Button>
        </div>
      </fieldset>
    </form>
  )
}

export default Form

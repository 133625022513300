import React from 'react'
import Section from '../Common/Section'
import Container from '../Common/Container'

import * as s from './ModuleSection2.module.scss'

interface ModuleSection2Props {
  title?: string
  className?: string
  id?: string
}

export const ModuleSection2: React.FC<ModuleSection2Props> = ({
  title,
  className,
  id,
  children,
}) => {
  return (
    <Section className={className} id={id}>
      <Container>
        {title && <h2 className={s.title}>{title}</h2>}
        {children}
      </Container>
    </Section>
  )
}
